<script setup>
import { ref } from "vue";
// import { Dialog, DialogPanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { useRoute } from "vue-router";

const { t, locales, localeProperties } = useI18n();
const route = useRoute();

const navigation = [
  {
    label: t("menu.main"),
    href: "/",
    locale: { en: "/", es: "/es", ca: "/ca" },
  },
  {
    label: t("menu.library"),
    href: "/library",
    locale: { en: "/library", es: "/es/biblioteca", ca: "/ca/biblioteca" },
  },
  // {
  //   label: t("menu.wip"),
  //   href: "/wip",
  //   locale: { en: "/wip", es: "/es/wip", ca: "/ca/wip" },
  // },
  {
    label: t("menu.mission"),
    href: "/mission",
    locale: { en: "/mission", es: "/es/mision", ca: "/ca/missio" },
  },
  {
    label: t("menu.green_line"),
    href: "/greenline",
    locale: { en: "/greenline", es: "/es/lineaverde", ca: "/ca/liniaverda" },
  },
  {
    label: t("menu.contact"),
    href: "/contact",
    locale: { en: "/contact", es: "/es/contacto", ca: "/ca/contacte" },
  },
];

const localePath = useLocalePath();
const switchLocalePath = useSwitchLocalePath();
const availableLocales = ref(
  locales.value.filter((l) => l.code !== localeProperties.value.code)
);
const currentLocale = ref(localeProperties.value.code);

const mobileMenuOpen = ref(false);
const currentRoute = route.path;

const toggleMenu = () => {
  console.log('toggle')
  mobileMenuOpen.value = !mobileMenuOpen.value;
}
</script>

<template>
  <header>
    <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
      <a href="#" class="-m-1.5 p-1.5">
        <span class="sr-only">Puigdellivol</span>
        <img class="h-16 w-auto" src="/img/puigdellivol.png" alt="Puigdellivol" />
      </a>
      <div class="flex lg:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          @click="toggleMenu">
          <span class="sr-only">{{ $t("menu.open_menu") }}</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="lg:flex hidden">
        <div class="lg:flex lg:gap-x-6 items-center">
          <NuxtLink v-for="item in navigation" :to="localePath({ path: item.href, params: { lang: currentLocale } })"
            class="!text-[#333333] cursor-pointer uppercase tracking-[.25em] text-xs font-regular leading-5 text-gray-900 border-b border-b-2 hover:border-[#333333] px-4 transition duration-300"
            :class="{
              'border-b border-[#333333]':
                currentRoute === item.locale[currentLocale],
              'border-transparent': currentRoute !== item.locale[currentLocale],
            }">{{ item.label }}</NuxtLink>
        </div>
        <div class="lg:flex pl-12 gap-2 uppercase tracking-[.25em] text-xs font-regular leading-5 text-center">
          <NuxtLink v-for="locale in availableLocales" class="hover:font-bold !text-gray-900"
            :to="switchLocalePath(locale.code)">{{ locale.name }}</NuxtLink>
        </div>
      </div>
    </nav>
    <Dialog class="lg:hidden w-[100vw] h-[100vh] p-dialog-maximized" modal v-model:visible="mobileMenuOpen">
      <div class="fixed inset-0 z-10" />
      <div
        class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Puigdellivol</span>
            <img class="h-8 w-auto" src="/img/puigdellivol.png" alt="Puigdellivol" />
          </a>
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="toggleMenu">
            <span class="sr-only">{{ $t("menu.close_menu") }}</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <NuxtLink v-for="item in navigation" :key="item.label"
                :to="localePath({ path: item.href, params: { lang: currentLocale } })"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 !text-gray-900 hover:bg-gray-50">
                {{
                  item.label }}</NuxtLink>
              <div class="relative mb-4">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-gray-300" />
                </div>
              </div>
              <div class="relative !mt-4">
                <NuxtLink v-for="locale in availableLocales" class="hover:font-bold !text-gray-900"
                  :to="switchLocalePath(locale.code)">
                  <div
                    class="flex flex-col -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 !text-gray-900 hover:bg-gray-50">
                    {{ $t(`lang.${locale.name}`) }}
                  </div>
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  </header>
</template>
