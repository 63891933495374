<script setup>
const { t } = useI18n();
const localePath = useLocalePath();

const information = ref([
  { label: t("menu.main"), href: "/" },
  { label: t("menu.library"), href: "/library" },
  // { label: t("menu.wip"), href: "/wip" },
  { label: t("menu.mission"), href: "/mission" },
  { label: t("menu.green_line"), href: "/greenline" },
  { label: t("menu.contact"), href: "/contact" },
]);

const legal = ref([
  {
    label: t("footer_module.legal_notice"),
    href: "/legal/legal-notice",
    target: "_self",
    external: false,
  },
  {
    label: t("footer_module.privacy_policy"),
    href: "/legal/privacy-policy",
    target: "_self",
    external: false,
  },
  {
    label: t("footer_module.compliance_reporting_channel"),
    href: "https://registradenuncia.com/B08202194",
    target: "_blank",
    external: true,
  },
  {
    label: t("footer_module.regulatory_policy"),
    href: "/politica_reguladora.pdf",
    target: "_blank",
    external: true,
  },
  {
    label: t("footer_module.internal_regulations"),
    href: "/reglamento_interno.pdf",
    target: "_blank",
    external: true,
  },
]);

const officeWorkshop = ref([
  {
    icon: "fa-solid fa-location-dot",
    label:
      "Ctra. C16-C km 1,8 P.I. Sant Isidre I, 08272 Sant Fruitós de Bages (Barcelona)",
  },
  {
    icon: "fa-solid fa-phone",
    label: "+34 93 877 29 39",
  },
]);

const officeBcn = ref([
  {
    icon: "fa-solid fa-location-dot",
    label: "C. Tànger, 48 Baixos, 08018 Barcelona",
  },
]);

const social = ref([
  {
    icon: "fa-brands fa-square-facebook",
    href: "#",
  },
  {
    icon: "fa-brands fa-square-instagram",
    href: "#",
  },
  {
    icon: "fa-brands fa-square-pinterest",
    href: "#",
  },
]);

const currentYear = new Date().getFullYear();
</script>

<template>
  <div class="bg-[#2C2C2C] text-white pt-10">
    <div class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
      <div class="w-full grid grid-cols-1 lg:grid-cols-4">
        <div class="max-w-[230px] hidden lg:block">
          <img src="/img/puigdellivol-negative.png" />
        </div>
        <div class="my-4 px-4 lg:px-8">
          <div class="font-semibold mb-4 uppercase tracking-[0.175em]">
            {{ $t("footer_module.info") }}
          </div>
          <div class="flex flex-col gap-2">
            <NuxtLink v-for="item in information" :to="localePath(item.href)"
              class="cursor-pointer hover:underline underline-offset-4 transition !text-white">{{ item.label }}
            </NuxtLink>
          </div>
        </div>
        <div class="my-4">
          <div class="px-4 lg:px-8">
            <div class="font-semibold mb-4 uppercase tracking-[0.175em]">
              {{ $t("footer_module.office_workshop") }}
            </div>
            <div v-for="item in officeWorkshop" class="leading-8 my-2 flex">
              <div class="w-[25px]">
                <font-awesome-icon :icon="item.icon" />
              </div>
              <div class="flex-1">
                <span>{{ item.label }}</span>
              </div>
            </div>
          </div>
          <div class="px-4 lg:px-8 mt-8">
            <div class="font-semibold mb-4 uppercase tracking-[0.175em]">
              {{ $t("footer_module.office_bcn") }}
            </div>
            <div v-for="item in officeBcn" class="leading-8 my-2 flex">
              <div class="w-[25px]">
                <font-awesome-icon :icon="item.icon" />
              </div>
              <div class="flex-1">
                <span>{{ item.label }}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="my-4 px-4 lg:px-8">
            <div class="font-semibold mb-4 uppercase tracking-[0.175em]">
              {{ $t("footer_module.social_media") }}
            </div>
            <div class="flex gap-3">
              <div v-for="item in social">
                <a class="text-4xl hover:opacity-80 transition" :href="item.href" target="_blank"><font-awesome-icon
                    :icon="item.icon" /></a>
              </div>
            </div>
          </div>
          <div class="my-4 px-4 lg:px-8 mt-8">
            <div class="font-semibold mb-4 uppercase tracking-[0.175em]">
              {{ $t("footer_module.legal") }}
            </div>
            <div class="flex flex-col gap-2">
              <NuxtLink v-for="item in legal" :to="localePath(item.href)" :target="item.target"
                :external="item.external"
                class="cursor-pointer hover:underline underline-offset-4 transition !text-white">{{ item.label }}
              </NuxtLink>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center w-full lg:hidden mt-8 mb-4 opacity-40">
          <img class="max-w-[150px]" src="/img/puigdellivol-negative.png" />
        </div>
      </div>
    </div>
    <div class="border-t border-[#414141] w-full flex items-center justify-center py-5">
      <span class="text-[#7A7A7A]"><span class="font-semibold">Puigdellivol</span> &copy;
        {{ currentYear }} · {{ $t("footer_module.rights_reserved") }}</span>
    </div>
  </div>
  <PostFooter />
</template>
